import * as React from "react"
import { Helmet } from "react-helmet"
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Row,
  Stack,
  Table,
} from "react-bootstrap"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import { PageProps, graphql, navigate, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Sfiggy } from "../components/sfiggy"

type DataProps = {
  metadataJson: Sfiggy & {
    file_path: {
      publicURL: string
      childImageSharp: {
        resize: {
          src: string
        }
      }
    }
  }
  allRarityJson: {
    nodes: [
      {
        rarity: [
          {
            label: string
            occurrence: number
            trait: string
          }
        ]
        layer: string
      }
    ]
  }
  allMetadataJson: {
    totalCount: number
  }
}

type ContextProps = {
  dna: string
  page: number
}

const CollectionArtTemplate: React.FC<PageProps<DataProps, ContextProps>> = ({
  data,
  pageContext,
}) => (
  <Layout>
    <Seo
      title={data.metadataJson.nft_name}
      description={`${data.metadataJson.nft_name} is part of the official ${data.allMetadataJson.totalCount} pieces NFT collection. Each piece is unique with different characterists and style.`}
      imageURL={data.metadataJson.file_path.childImageSharp.resize.src}
    />
    <Helmet>
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Collection",
    "item": "https://sfiggy.caplaz.com/roadmap/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "Page ${pageContext.page}",
    "item": "https://sfiggy.caplaz.com/collection/page/${pageContext.page}/"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "${data.metadataJson.nft_name}"
  }]
}
  `}</script>
    </Helmet>
    <Container>
      <Row>
        <Col>
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <Stack gap={1} direction={"horizontal"}>
                  <Button size="sm" variant="light" onClick={() => zoomIn()}>
                    Zoom In +
                  </Button>
                  <Button size="sm" variant="light" onClick={() => zoomOut()}>
                    Zoon Out -
                  </Button>
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => resetTransform()}
                  >
                    Reset
                  </Button>
                </Stack>
                <TransformComponent>
                  <img
                    src={data.metadataJson.file_path.publicURL}
                    alt={data.metadataJson.nft_name}
                    width={375}
                    height={625}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </Col>
        <Col>
          <Stack gap={3}>
            <Breadcrumb>
              <Breadcrumb.Item href="/roadmap/">Collection</Breadcrumb.Item>
              <Breadcrumb.Item href={`/collection/page/${pageContext.page}/`}>
                Page {pageContext.page}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{data.metadataJson.dna}</Breadcrumb.Item>
            </Breadcrumb>
            <h2>{data.metadataJson.nft_name}</h2>
            <a
              href={data.metadataJson.nft_url}
              title="Buy on OpenSea"
              target="_blank"
            >
              <img
                style={{
                  width: "220px",
                  borderRadius: "5px",
                  boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25)",
                }}
                src="https://storage.googleapis.com/opensea-static/Logomark/Badge%20-%20Available%20On%20-%20Light.png"
                alt="Available on OpenSea"
              />
            </a>
            <Table
              striped
              bordered
              hover
              size={"sm"}
              style={{
                marginTop: 50,
              }}
            >
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                  <th>Rarity</th>
                </tr>
              </thead>
              <tbody>
                {data.metadataJson.properties.map(property => (
                  <tr
                    key={property.trait_type}
                    onClick={() => {
                      navigate(
                        `/collection/property/${property.trait_type.toLowerCase()}/filter/${property.value.toLowerCase()}/`
                      )
                    }}
                  >
                    <td>{property.trait_type}</td>
                    <td>
                      <Link
                        to={`/collection/property/${property.trait_type.toLowerCase()}/filter/${property.value.toLowerCase()}/`}
                      >
                        {property.value}
                      </Link>
                    </td>
                    <td>
                      {
                        data.allRarityJson.nodes
                          .filter(node => node.layer === property.trait_type)[0]
                          .rarity.filter(
                            value => value.trait === property.value
                          )[0].label
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Stack>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CollectionArtTemplate

export const query = graphql`
  query ArtDetail($dna: String!) {
    metadataJson(dna: { eq: $dna }) {
      ...Sfiggy
      file_path {
        publicURL
        childImageSharp {
          resize(height: 512, width: 512, cropFocus: NORTH, pngQuality: 3) {
            src
          }
        }
      }
    }
    allRarityJson {
      nodes {
        layer
        rarity {
          label
          occurrence
          trait
        }
      }
    }
    allMetadataJson {
      totalCount
    }
  }
`
